<template>
  <div>
    <h1>Register at mpawer</h1>
    <p class="body-2 mt-3 grey--text">
      Already have an account?
      <router-link class="text-decoration-none" to="/login">log in</router-link>
    </p>

    <v-form v-model="isValid" ref="form">
      <InputGroup
        type="text"
        placeholder="Full Name"
        v-model="form.name"
        :rules="[formRules.required]"
      />

      <InputGroup
        type="email"
        placeholder="Email"
        v-model="form.email"
        :rules="[formRules.required, formRules.validEmail]"
      />

      <div class="map-input mb-5 mt-1">
        <gmap-autocomplete
          placeholder="Search From Google...!"
          @place_changed="getSearchLocation"
        >
        </gmap-autocomplete>
      </div>

      <InputGroup
        type="text"
        placeholder="Address"
        v-model="form.address"
        :rules="[formRules.required]"
      />

      <v-row>
        <v-col cols="6">
          <InputGroup
            type="text"
            placeholder="Country"
            v-model="form.country"
            :rules="[formRules.required]"
            disabled
          />
        </v-col>
        <v-col cols="6">
          <InputGroup
            type="city"
            placeholder="City"
            v-model="form.city"
            disabled
            :rules="[formRules.required]"
          />
        </v-col>
      </v-row>

      <InputGroup
        type="text"
        placeholder="Postal Code"
        v-model="form.postal_code"
        :rules="[formRules.required, validatePostalCode]"
      />

      <VuePhoneNumberInput
        v-model="form.contact"
        error-color="#FF5F5F"
        valid-color="#0DBFF2"
        color="#0DBFF2"
        size="lg"
        required
        :border-radius="8"
        no-example
        @update="validatePhone"
        :default-country-code="form.country_code"
        class="number-input mb-8"
      />

      <!-- <v-select
        :items="genderList"
        item-text="text"
        item-value="value"
        placeholder="Gender"
        v-model="form.gender"
      ></v-select> -->

      <InputGroup
        type="password"
        placeholder="Password"
        v-model="form.password"
        :rules="[formRules.required, formRules.minPasswordLength]"
      />
      <InputGroup
        type="password"
        placeholder="Confirm Password"
        v-model="form.password_confirmation"
        :rules="[formRules.required, formRules.minPasswordLength]"
      />

      <div class="d-flex align-items-center">
        <v-checkbox
          v-model="terms_conditions"
          :rules="[formRules.required]"
          color="primary"
          hide-details
          class="mt-0"
        ></v-checkbox>
        <p class="caption mt-3">
          By signing in I have read and agree to the
          <span class="text-decoration-none primary--text">
            Terms and Conditions
          </span>
          and
          <span class="primary--text"> Privacy Policy </span>.
        </p>
      </div>

      <Button
        block
        large
        class="my-10"
        @click="createNewAccount"
        :loading="loading.user"
        :disabled="!isValidPhone || !isValid"
        >Create Account</Button
      >
    </v-form>
  </div>
</template>

<script>
import { InputGroup, Button } from "@/components";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import {
  validEmail,
  required,
  minPasswordLength
} from "@/helpers/form-validations";
// import { GENDER_LIST } from "@/constants";

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");

import lookUp from "country-code-lookup";
import { postcodeValidator } from "postcode-validator";

export default {
  name: "register",
  components: { InputGroup, Button, VuePhoneNumberInput },
  data() {
    return {
      isValid: false,
      isValidPhone: false,
      phoneNumberWithCallingCode: "",
      terms_conditions: false,
      form: {
        name: "",
        contact: "",
        email: "",
        password: "",
        password_confirmation: "",
        // gender: "male",
        role: "seller",
        address: "",
        postal_code: "",
        city: "",
        country: "",
        longitude: 50.5039,
        latitude: 4.4699,
        country_code: "BE"
      }
    };
  },
  computed: {
    ...mapState(["loading"]),
    formRules() {
      return {
        validEmail,
        required,
        minPasswordLength
      };
    }
    // genderList() {
    //   return GENDER_LIST;
    // },
  },
  methods: {
    ...mapActions(["CREATE_USER"]),
    validatePostalCode(val) {
      if (!val) return "Postal Code is required.";
      if (!this.form.country_code) return "Please Enter the country first.";
      return (
        postcodeValidator(val, this.form.country_code) || "Invalid Postal Code"
      );
    },
    validatePhone(phone) {
      const { isValid } = phone;
      if (isValid) {
        this.phoneNumberWithCallingCode = phone.e164;
        this.isValidPhone = true;
      } else {
        this.isValidPhone = false;
      }
    },
    createNewAccount() {
      if (this.isValidPhone && this.isValid && this.$refs.form.validate()) {
        this.form.contact = this.phoneNumberWithCallingCode;
        this.CREATE_USER(this.form);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getSearchLocation(place) {
      const { geometry, formatted_address, address_components } = place;
      const obj = {
        latitude: geometry.location.lat(),
        longitude: geometry.location.lng(),
        address: formatted_address
      };

      address_components.forEach(component => {
        if (component.types.includes("postal_code"))
          obj.postal_code = component.long_name;
        if (component.types.includes("country")) {
          obj.country = component.long_name;
          obj.country_code = component.short_name;
        }
        if (component.types.includes("locality"))
          obj.city = component.long_name;
      });

      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          this.form[key] = obj[key];
        }
      }
    }
  },

  watch: {
    "form.country"(val) {
      if (!val) return;
      const country_name = this.capitalizeFirstLetter(val);
      const country = lookUp.byCountry(country_name);
      if (!country) return (this.form.country_code = "BE");
      const { internet } = country;
      this.form.country_code = internet;
    }
  }
};
</script>

<style lang="scss" scoped>
.number-input {
  margin-bottom: 1rem;
  &::v-deep input {
    border-color: transparent;
    border-bottom: 1px solid #9e9e9e;
  }
}

.map-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  input {
    width: 100%;
    border: none;
    outline: none;
    font-family: Roboto, sans-serif;
    font-size: 16px;
  }
}
</style>
